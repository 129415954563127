import type { AccountScore } from '../src';
import type { Profile } from './indexing';
import { numberMatcher, stringMatcher, Traits } from './traits';

export class Scores {
  private traits: Traits;
  private prefix = '';

  constructor(profile: Profile, prefix: string) {
    this.traits = new Traits(profile);
    this.prefix = prefix;
  }

  get fitGrade() {
    return numberMatcher(this.traits, `${this.prefix}.fit_grade`);
  }

  get fitGradeLetter() {
    return stringMatcher<AccountScore['fit_grade_letter']>(
      this.traits,
      `${this.prefix}.fit_grade_letter`
    );
  }
}
