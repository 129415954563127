import type { Profile } from './indexing';

export function performed(
  profile: Profile,
  event: string,
  times: number | undefined = 1
) {
  return performedAtLeast(profile, times, event);
}

export function performedAnyOf(profile: Profile, ...events: string[]) {
  return events.some((event) => performed(profile, event));
}

export function performedAtLeast(
  profile: Profile,
  times: number,
  event: string
) {
  return profile.counts.count(`events.${event}`.toLowerCase()) >= times;
}

export class Events {
  private profile: Profile;
  constructor(profile: Profile) {
    this.profile = profile;
  }

  public performed(event: string, times: number | undefined = 1) {
    return performed(this.profile, event, times);
  }

  public performedAnyOf(...events: string[]) {
    return performedAnyOf(this.profile, ...events);
  }

  public performedAtLeast(times: number, event: string) {
    return performedAtLeast(this.profile, times, event);
  }

  public get not() {
    return {
      performed: (event: string, times: number | undefined = 1) => {
        return !this.performed(event, times);
      },
      performedAnyOf: (...events: string[]) => {
        return !this.performedAnyOf(...events);
      },
      performedAtLeast: (times: number, event: string) => {
        return !this.performedAtLeast(times, event);
      },
    };
  }
}
