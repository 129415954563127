import { CountMinSketch, JsBloom } from '@getkoala/js-bloom';
import { flattie } from 'flattie';
import type { AnalyticsEvent, JSONValue, PageView } from '../src';

export interface Profile {
  counts: CountMinSketch;
  bloom: JsBloom;
  sessionStart?: Date;
}

const removeIndexes = (key: string) => {
  const replaced = key.replace(/\.(\d+)/g, '.[]');
  return replaced;
};

export type Indexable =
  | AnalyticsEvent
  | JSONValue
  | Record<string, JSONValue>
  | PageView
  | Date;

function isEvent(e: unknown): e is AnalyticsEvent {
  if (typeof e !== 'object') {
    return false;
  }

  if (e?.hasOwnProperty('event')) {
    return true;
  }

  return false;
}

function isPageView(e: unknown): e is PageView {
  if (typeof e !== 'object') {
    return false;
  }

  if (e?.hasOwnProperty('page')) {
    if ((e as PageView).page['path']) {
      return true;
    }
  }

  return false;
}

function increment(profile: Profile, key: string, by: number = 1) {
  profile.counts.add(key.toLowerCase(), by);
}

function setCount(profile: Profile, key: string, to: number) {
  profile.counts.setCount(key.toLowerCase(), to);
}

function add(profile: Profile, key: string) {
  profile.bloom.add(key.toLowerCase());
}

function indexPair(profile: Profile, key: string, value: unknown) {
  const cleanKey = removeIndexes(key);

  if (typeof value === 'number') {
    // overrides the counts in the Count Min Sketch,
    // as opposed to incrementing the count.
    // This is what allows us to query traits that are numbers
    setCount(profile, `${cleanKey}:${value}`, value);
    setCount(profile, cleanKey, value);
  } else {
    add(profile, `${cleanKey}:${value}`);
    add(profile, cleanKey);
  }

  return profile;
}

export function index(
  profile: Profile,
  element: Indexable | Indexable[]
): void {
  if (Array.isArray(element)) {
    element.forEach((e) => {
      index(profile, e);
    });

    return;
  }

  if (isEvent(element)) {
    increment(profile, `events.${element.event}`);
    const props = Object.entries(flattie(element.properties ?? {}));

    props.forEach(([key, value]: [key: string, value: unknown]) => {
      indexPair(profile, key, value);
    });

    return;
  }

  if (isPageView(element)) {
    increment(profile, `page_views.${element.page.path}`);
    return;
  }

  if (typeof element === 'object') {
    const flat = Object.entries(flattie(element));
    flat.forEach(([key, value]: [key: string, value: unknown]) => {
      indexPair(profile, key, value);
    });

    return;
  }

  return;
}
