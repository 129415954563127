import createLucideIcon from '../createLucideIcon';

const LayoutGrid = createLucideIcon('LayoutGrid', [
  ['rect', { x: '3', y: '3', width: '7', height: '7', key: '1q9d4d' }],
  ['rect', { x: '14', y: '3', width: '7', height: '7', key: '1kofyi' }],
  ['rect', { x: '14', y: '14', width: '7', height: '7', key: '18jxcw' }],
  ['rect', { x: '3', y: '14', width: '7', height: '7', key: '1omegr' }],
]);

export default LayoutGrid;
