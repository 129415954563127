import type { Profile } from './indexing';

// TODO:
// - add UTM matchers
// - add query params matchers
// - add referrer matchers
// - add traffic source matchers
// - add user agent matchers
// - turn this into a full browser API

export function seen(
  profile: Profile,
  path: string,
  times: number | undefined = 1
) {
  return profile.counts.count(`page_views.${path}`.toLowerCase()) >= times;
}

export const viewed = seen;

export function viewing(_profile: Profile, path: string) {
  return window.location.pathname.toLowerCase() === path.toLowerCase();
}

export class Page {
  constructor(private profile: Profile) {
    this.profile = profile;
  }

  public seen(path: string, times: number | undefined = 1) {
    return seen(this.profile, path, times);
  }

  public viewed(path: string) {
    return viewed(this.profile, path);
  }

  public viewing(path: string) {
    return viewing(this.profile, path);
  }

  public get not() {
    return {
      seen: (path: string, times: number | undefined = 1) => {
        return !this.seen(path, times);
      },
      viewed: (path: string) => {
        return !this.viewed(path);
      },
      viewing: (path: string) => {
        return !this.viewing(path);
      },
    };
  }
}
