import createLucideIcon from '../createLucideIcon';

const DollarSign = createLucideIcon('DollarSign', [
  ['line', { x1: '12', y1: '2', x2: '12', y2: '22', key: '1k6o5o' }],
  [
    'path',
    { d: 'M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6', key: '1b0p4s' },
  ],
]);

export default DollarSign;
