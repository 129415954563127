import { flattie } from 'flattie';
import type { JSONValue } from '../src';
import type { Profile } from './indexing';

export function stringMatcher<T extends string>(traits: Traits, key: string) {
  return {
    is: (target: T): boolean => traits.is(key, target),
    exists: (): boolean => traits.has(key),
    includesItem: (...targets: T[]): boolean => traits.includes(key, targets),
    includesAnyOf: (...targets: T[]): boolean =>
      traits.includesAnyOf(key, targets),
    not: {
      is: (target: T): boolean => !traits.is(key, target),
      includesItem: (...targets: T[]): boolean => !traits.includes(key, targets),
      includesAnyOf: (...targets: T[]): boolean => !traits.includesAnyOf(key, targets)
    }
  };
}

export function arrayMatcher<T extends string>(traits: Traits, key: string) {
  return {
    includesItem: (target: T): boolean => traits.includes(key, target),
    includesAnyOf: (...targets: T[]): boolean =>
      traits.includesAnyOf(key, targets),
    includesAllOf: (...targets: T[]): boolean =>
      traits.includesAllOf(key, targets),
    not: {
      includesItem: (target: T): boolean => !traits.includes(key, target),
      includesAnyOf: (...targets: T[]): boolean => !traits.includesAnyOf(key, targets),
      includesAllOf: (...targets: T[]): boolean => !traits.includesAllOf(key, targets)
    }
  };
}

export function numberMatcher(traits: Traits, key: string) {
  return {
    exists: (): boolean => traits.has(key),
    is: (target: number): boolean => traits.is(key, target),
    greaterThan: (target: number): boolean => traits.greaterThan(key, target),
    greaterThanOrEqual: (target: number): boolean =>
      traits.greaterThanOrEqual(key, target),
    lessThan: (target: number): boolean => traits.lessThan(key, target),
    lessThanOrEqual: (target: number): boolean =>
      traits.lessThanOrEqual(key, target),
    not: {
      is: (target: number): boolean => !traits.is(key, target),
      greaterThan: (target: number): boolean => !traits.greaterThan(key, target),
      greaterThanOrEqual: (target: number): boolean => !traits.greaterThanOrEqual(key, target),
      lessThan: (target: number): boolean => !traits.lessThan(key, target),
      lessThanOrEqual: (target: number): boolean => !traits.lessThanOrEqual(key, target),
    }
  };
}

export function has(profile: Profile, trait: string) {
  const t = trait.toLowerCase();
  return !!(profile.bloom.test(t) || profile.counts.count(t));
}

export function greaterThan(profile: Profile, trait: string, value: number) {
  return profile.counts.count(trait.toLowerCase()) > value;
}

export function greaterThanOrEqual(
  profile: Profile,
  trait: string,
  value: number
) {
  return profile.counts.count(trait.toLowerCase()) >= value;
}

export function lessThan(profile: Profile, trait: string, value: number) {
  return profile.counts.count(trait.toLowerCase()) < value;
}

export function lessThanOrEqual(
  profile: Profile,
  trait: string,
  value: number
) {
  return profile.counts.count(trait.toLowerCase()) <= value;
}

export function is(profile: Profile, trait: string, value?: JSONValue) {
  if (value === undefined) {
    return has(profile, trait);
  }

  if (typeof value === 'object') {
    return matchesObject(profile, trait, value as Record<string, JSONValue>);
  }

  if (typeof value === 'number') {
    return profile.counts.count(trait.toLowerCase()) === value;
  }

  return profile.bloom.test(`${trait}:${value}`.toLowerCase());
}

export function matchesObject(
  profile: Profile,
  trait: string,
  value: Record<string, JSONValue>
): boolean {
  const flat = flattie(value);
  const allKeys = Object.keys(flat);
  return allKeys.every((key) => is(profile, `${trait}.${key}`, value[key]));
}

export function includes(profile: Profile, trait: string, value: JSONValue) {
  return is(profile, trait, value) || is(profile, `${trait}.[]`, value);
}

export function includesAnyOf(
  profile: Profile,
  trait: string,
  values: JSONValue[]
) {
  return values.some((value) => includes(profile, trait, value));
}

export function includesAllOf(
  profile: Profile,
  trait: string,
  values: JSONValue[]
) {
  return values.every((value) => includes(profile, trait, value));
}

export class Traits {
  constructor(private profile: Profile) {
    this.profile = profile;
  }

  public has(trait: string) {
    return has(this.profile, trait);
  }

  public greaterThan(trait: string, value: number) {
    return greaterThan(this.profile, trait, value);
  }

  public greaterThanOrEqual(trait: string, value: number) {
    return greaterThanOrEqual(this.profile, trait, value);
  }

  public lessThan(trait: string, value: number) {
    return lessThan(this.profile, trait, value);
  }

  public lessThanOrEqual(trait: string, value: number) {
    return lessThanOrEqual(this.profile, trait, value);
  }

  public is(trait: string, value?: JSONValue) {
    return is(this.profile, trait, value);
  }

  public matchesObject(trait: string, value: Record<string, JSONValue>) {
    return matchesObject(this.profile, trait, value);
  }

  public includes(trait: string, value: JSONValue) {
    return includes(this.profile, trait, value);
  }

  public includesAnyOf(trait: string, values: JSONValue[]) {
    return includesAnyOf(this.profile, trait, values);
  }

  public includesAllOf(trait: string, values: JSONValue[]) {
    return includesAllOf(this.profile, trait, values);
  }

  public get not() {
    return {
      has: (trait: string) => !this.has(trait),
      greaterThan: (trait: string, value: number) =>
        !this.greaterThan(trait, value),
      greaterThanOrEqual: (trait: string, value: number) =>
        !this.greaterThanOrEqual(trait, value),
      lessThan: (trait: string, value: number) => !this.lessThan(trait, value),
      lessThanOrEqual: (trait: string, value: number) =>
        !this.lessThanOrEqual(trait, value),
      is: (trait: string, value?: JSONValue) => !this.is(trait, value),
      matchesObject: (trait: string, value: Record<string, JSONValue>) =>
        !this.matchesObject(trait, value),
      includes: (trait: string, value: JSONValue) =>
        !this.includes(trait, value),
      includesAnyOf: (trait: string, values: JSONValue[]) =>
        !this.includesAnyOf(trait, values),
      includesAllOf: (trait: string, values: JSONValue[]) =>
        !this.includesAllOf(trait, values),
    };
  }
}
