export function inSegment(...predicates: boolean[]): boolean {
  return predicates.every(Boolean);
}

export async function when<T>(
  condition: () => boolean | Promise<boolean>,
  action?: (() => T | Promise<T>) | null,
  timeout?: number
): Promise<void> {
  return new Promise(async (resolve, _reject) => {
    if (await condition()) {
      action?.();
      resolve();
      return;
    }

    const check = () =>
      setTimeout(async () => {
        if (await condition()) {
          action?.();
          resolve();
        } else {
          check();
        }
      }, timeout);

    check();
  });
}
