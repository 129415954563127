import type { Profile } from './indexing';
import { Traits } from './traits';
import {
  BusinessClassification,
  CompanyTags,
  CompanyTechCategory,
  CompanyType,
  Country,
  CountryCode,
  EmployeeRange,
  EstimatedRevenueRange,
  Industry,
  IndustryGroup,
  Sector,
  SubIndustry,
  TechTags,
  Timezone,
} from './__types__/company';

function stringMatcher<T extends string>(traits: Traits, key: string) {
  return {
    exists: () => traits.has(key),
    is: (target: T): boolean => traits.is(key, target),
    includesItem: (...targets: T[]): boolean => traits.includes(key, targets),
    includesAnyOf: (...targets: T[]): boolean =>
      traits.includesAnyOf(key, targets),
  };
}

function arrayMatcher<T extends string>(traits: Traits, key: string) {
  return {
    includesItem: (target: T): boolean => traits.includes(key, target),
    includesAnyOf: (...targets: T[]): boolean =>
      traits.includesAnyOf(key, targets),
    includesAllOf: (...targets: T[]): boolean =>
      traits.includesAllOf(key, targets),
  };
}

function numberMatcher(traits: Traits, key: string) {
  return {
    exists: () => traits.has(key),
    is: (target: number): boolean => traits.is(key, target),
    greaterThan: (target: number): boolean => traits.greaterThan(key, target),
    greaterThanOrEqual: (target: number): boolean =>
      traits.greaterThanOrEqual(key, target),
    lessThan: (target: number): boolean => traits.lessThan(key, target),
    lessThanOrEqual: (target: number): boolean =>
      traits.lessThanOrEqual(key, target),
  };
}

export class Company {
  private traits: Traits;

  constructor(profile: Profile) {
    this.traits = new Traits(profile);
  }

  public get name() {
    return stringMatcher(this.traits, 'company.name');
  }

  public get domain() {
    return stringMatcher(this.traits, 'company.domain');
  }

  public get sector() {
    return stringMatcher<Sector>(this.traits, 'company.category.sector');
  }

  public get type() {
    return stringMatcher<CompanyType>(this.traits, 'company.type');
  }

  public get industryGroup() {
    return stringMatcher<IndustryGroup>(
      this.traits,
      'company.category.industryGroup'
    );
  }

  public get industry() {
    return stringMatcher<Industry>(this.traits, 'company.category.industry');
  }

  public get subIndustry() {
    return stringMatcher<SubIndustry>(
      this.traits,
      'company.category.subIndustry'
    );
  }

  public get foundedYear() {
    return numberMatcher(this.traits, 'company.foundedYear');
  }

  public get timezone() {
    return stringMatcher<Timezone>(this.traits, 'company.timezone');
  }

  public get city() {
    return stringMatcher(this.traits, 'company.geo.city');
  }

  public get state() {
    return stringMatcher(this.traits, 'company.geo.state');
  }

  public get stateCode() {
    return stringMatcher(this.traits, 'company.geo.stateCode');
  }

  public get country() {
    return stringMatcher<Country>(this.traits, 'company.geo.country');
  }

  public get countryCode() {
    return stringMatcher<CountryCode>(this.traits, 'company.geo.countryCode');
  }

  public get employeeCount() {
    return numberMatcher(this.traits, 'company.metrics.employees');
  }

  public get employeesRange() {
    return stringMatcher<EmployeeRange>(
      this.traits,
      'company.metrics.employeesRange'
    );
  }

  public get marketCap() {
    return numberMatcher(this.traits, 'company.metrics.marketCap');
  }

  public get amountRaised() {
    return numberMatcher(this.traits, 'company.metrics.raised');
  }

  public get annualRevenue() {
    return numberMatcher(this.traits, 'company.metrics.annualRevenue');
  }

  public get estimatedAnnualRevenue() {
    return stringMatcher<EstimatedRevenueRange>(
      this.traits,
      'company.metrics.estimatedAnnualRevenue'
    );
  }

  public get tech() {
    return arrayMatcher<TechTags>(this.traits, 'company.tech');
  }

  public get techCategories() {
    return arrayMatcher<CompanyTechCategory>(
      this.traits,
      'company.techCategories'
    );
  }

  public get tags() {
    return arrayMatcher<CompanyTags | BusinessClassification>(
      this.traits,
      'company.tags'
    );
  }

  public isB2B() {
    return this.tags.includesItem('B2B');
  }

  public isB2C() {
    return this.tags.includesItem('B2C');
  }

  public isEnterprise() {
    return this.tags.includesItem('Enterprise');
  }

  public isEcommerce() {
    return this.tags.includesItem('E-commerce');
  }

  public isSaas() {
    return this.tags.includesItem('SAAS');
  }
}
