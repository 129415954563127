import createLucideIcon from '../createLucideIcon';

const ToggleRight = createLucideIcon('ToggleRight', [
  [
    'rect',
    {
      x: '2',
      y: '6',
      width: '20',
      height: '12',
      rx: '6',
      ry: '6',
      key: 'cdopvd',
    },
  ],
  ['circle', { cx: '16', cy: '12', r: '2', key: '4ma0v8' }],
]);

export default ToggleRight;
