import type { Profile } from './indexing';
import { stringMatcher, Traits } from './traits';
import { Country, CountryCode, Timezone } from './__types__/company';
import { Role, Seniority, SubRole } from './__types__/person';

export class Person {
  private traits: Traits;

  constructor(profile: Profile) {
    this.traits = new Traits(profile);
  }

  public get timezone() {
    return stringMatcher<Timezone>(this.traits, 'person.timezone');
  }

  public get city() {
    return stringMatcher(this.traits, 'person.geo.city');
  }

  public get state() {
    return stringMatcher(this.traits, 'person.geo.state');
  }

  public get stateCode() {
    return stringMatcher(this.traits, 'person.geo.stateCode');
  }

  public get country() {
    return stringMatcher<Country>(this.traits, 'person.geo.country');
  }

  public get countryCode() {
    return stringMatcher<CountryCode>(this.traits, 'person.geo.countryCode');
  }

  public get company() {
    return stringMatcher(this.traits, 'person.employment.name');
  }

  public get title() {
    return stringMatcher(this.traits, 'person.employment.title');
  }

  public get role() {
    return stringMatcher<Role>(this.traits, 'person.employment.role');
  }

  public get subRole() {
    return stringMatcher<SubRole>(this.traits, 'person.employment.subRole');
  }

  public get seniority() {
    return stringMatcher<Seniority>(this.traits, 'person.employment.seniority');
  }
}
