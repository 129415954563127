import createLucideIcon from '../createLucideIcon';

const ExternalLink = createLucideIcon('ExternalLink', [
  [
    'path',
    {
      d: 'M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6',
      key: 'a6xqqp',
    },
  ],
  ['polyline', { points: '15 3 21 3 21 9', key: 'mznyad' }],
  ['line', { x1: '10', y1: '14', x2: '21', y2: '3', key: '19d9un' }],
]);

export default ExternalLink;
