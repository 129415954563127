import createLucideIcon from '../createLucideIcon';

const Ticket = createLucideIcon('Ticket', [
  [
    'path',
    {
      d: 'M3 7v2a3 3 0 1 1 0 6v2c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-2a3 3 0 1 1 0-6V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Z',
      key: 'nswdcl',
    },
  ],
  ['path', { d: 'M13 5v2', key: 'dyzc3o' }],
  ['path', { d: 'M13 17v2', key: '1ont0d' }],
  ['path', { d: 'M13 11v2', key: '1wjjxi' }],
]);

export default Ticket;
