import createLucideIcon from '../createLucideIcon';

const CurlyBraces = createLucideIcon('CurlyBraces', [
  [
    'path',
    {
      d: 'M8 3H7a2 2 0 0 0-2 2v5a2 2 0 0 1-2 2 2 2 0 0 1 2 2v5c0 1.1.9 2 2 2h1',
      key: 'ezmyqa',
    },
  ],
  [
    'path',
    {
      d: 'M16 21h1a2 2 0 0 0 2-2v-5c0-1.1.9-2 2-2a2 2 0 0 1-2-2V5a2 2 0 0 0-2-2h-1',
      key: 'e1hn23',
    },
  ],
]);

export default CurlyBraces;
